import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Invoice } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Loading } from '@wayste/sour-ui';
import { DateRangePicker } from '@wayste/sour-ui';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import { CSVLink } from 'react-csv';

const PaymentsBreakdownReport = () => {
    const [loading, setLoading] = useState(false);
    const { setShowToast } = useSourContext();
    const client = useWaysteClient();
    const [downloadableData, setDownloadableData] = useState<string>('');
    const [dateRange, setDateRange] = useState<{ from: Date | undefined; to: Date | undefined }>({
        from: startOfMonth(addMonths(new Date(), -1)),
        to: endOfMonth(addMonths(new Date(), -1)),
    });

    const getReport = async () => {
        if (!dateRange.from || !dateRange.to) {
            setShowToast({
                message: 'Please select a date range',
                severity: 'warning',
            });
        } else {
            fetchReport({
                startDate: format(dateRange.from, 'yyyy-MM-dd'),
                endDate: format(dateRange.to, 'yyyy-MM-dd'),
            });
        }
    };

    const fetchReport = async (params: Invoice.TaxLiabilityReportCreate) => {
        try {
            setLoading(true);
            const data = await client.invoice().wayste.reports.payemntsBreakdown(params);
            setDownloadableData(data);
        } catch (error) {
            console.error(error);
            setShowToast({
                message: 'An error occurred while fetching the report',
                severity: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex h-full flex-col bg-neutral-50 px-4 py-8 md:px-5">
            <div className="mb-2 flex flex-col justify-between gap-1 md:flex-row md:items-center">
                <div className="pb-2">
                    <h1 className="mb-2 text-[22px] font-semibold leading-6 tracking-wide text-zinc-700">Reports</h1>
                    <h2 className="text-md font-normal leading-6 tracking-tight text-zinc-700">Payments Breakdown Report</h2>
                </div>

                <div className="flex w-full md:max-w-[250px]">
                    <DateRangePicker value={dateRange} onChange={setDateRange} />
                </div>
            </div>

            {loading && (
                <div className="flex size-full h-full flex-col items-center justify-center gap-4">
                    <Loading />
                    <span className="font-semibold uppercase leading-tight tracking-wide text-zinc-700">This may take a second...</span>
                </div>
            )}

            {!loading && (
                <div className="mx-auto grid max-w-4xl grid-cols-2 gap-y-1">
                    <div className="flex flex-col">
                        <Button className="btn-primary" onClick={getReport}>
                            Get Payment Customer Reconciliation Report
                        </Button>
                        {downloadableData.length === 0 ? null : (
                            <CSVLink
                                filename={`Payment_Customer_Reconciliation_Report_${new Date().toLocaleDateString()}`}
                                data={downloadableData}
                                className="btn-primary-text-only"
                            >
                                Download Payment Customer Reconciliation Report
                            </CSVLink>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaymentsBreakdownReport;
